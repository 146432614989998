type IconProps = {
  color?: string
}

const InstagramIcon = (props: IconProps) => (
  <span data-nosnippet>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM12.4817 7.51835C13.3919 7.47693 13.6827 7.4668 16.0003 7.4668H15.9976C18.3159 7.4668 18.6056 7.47693 19.5159 7.51835C20.4243 7.55995 21.0448 7.70378 21.5888 7.9148C22.1506 8.13258 22.6252 8.42414 23.0999 8.89881C23.5746 9.37313 23.8661 9.84922 24.0848 10.4105C24.2946 10.9531 24.4386 11.5732 24.4813 12.4816C24.5222 13.3918 24.5328 13.6827 24.5328 16.0002C24.5328 18.3178 24.5222 18.6079 24.4813 19.5181C24.4386 20.4262 24.2946 21.0465 24.0848 21.5893C23.8661 22.1503 23.5746 22.6264 23.0999 23.1007C22.6258 23.5754 22.1504 23.8677 21.5893 24.0856C21.0464 24.2967 20.4256 24.4405 19.5171 24.4821C18.6069 24.5235 18.3169 24.5336 15.9992 24.5336C13.6819 24.5336 13.3912 24.5235 12.481 24.4821C11.5727 24.4405 10.9524 24.2967 10.4095 24.0856C9.84857 23.8677 9.37248 23.5754 8.89834 23.1007C8.42384 22.6264 8.13229 22.1503 7.91415 21.5891C7.7033 21.0465 7.55948 20.4264 7.5177 19.5179C7.47646 18.6077 7.46614 18.3178 7.46614 16.0002C7.46614 13.6827 7.47681 13.3917 7.51752 12.4814C7.55841 11.5733 7.70241 10.9531 7.91397 10.4103C8.13264 9.84922 8.4242 9.37313 8.89887 8.89881C9.37319 8.42432 9.84928 8.13276 10.4105 7.9148C10.9531 7.70378 11.5732 7.55995 12.4817 7.51835Z"
        fill={props.color || 'white'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.234 9.00409C15.3827 9.00385 15.5426 9.00393 15.7151 9.004L15.9995 9.00409C18.278 9.00409 18.548 9.01226 19.4477 9.05315C20.2798 9.0912 20.7313 9.23022 21.0321 9.34702C21.4303 9.50169 21.7143 9.68658 22.0128 9.98525C22.3114 10.2839 22.4963 10.5684 22.6513 10.9666C22.7681 11.267 22.9073 11.7186 22.9452 12.5506C22.9861 13.4502 22.995 13.7204 22.995 15.9978C22.995 18.2751 22.9861 18.5453 22.9452 19.4449C22.9072 20.2769 22.7681 20.7285 22.6513 21.0289C22.4967 21.4271 22.3114 21.7107 22.0128 22.0092C21.7141 22.3079 21.4305 22.4928 21.0321 22.6474C20.7317 22.7648 20.2798 22.9034 19.4477 22.9415C18.5482 22.9824 18.278 22.9913 15.9995 22.9913C13.7209 22.9913 13.4509 22.9824 12.5513 22.9415C11.7193 22.9031 11.2678 22.764 10.9668 22.6472C10.5685 22.4926 10.2841 22.3077 9.98543 22.009C9.68676 21.7103 9.50187 21.4266 9.34684 21.0282C9.23004 20.7278 9.09084 20.2762 9.05297 19.4442C9.01208 18.5446 9.00391 18.2744 9.00391 15.9956C9.00391 13.7168 9.01208 13.448 9.05297 12.5485C9.09102 11.7165 9.23004 11.2649 9.34684 10.9641C9.50151 10.5659 9.68676 10.2814 9.98543 9.98276C10.2841 9.68409 10.5685 9.4992 10.9668 9.34418C11.2676 9.22684 11.7193 9.08818 12.5513 9.04995C13.3385 9.0144 13.6436 9.00373 15.234 9.00195V9.00409ZM20.554 10.4209C19.9886 10.4209 19.5299 10.8791 19.5299 11.4446C19.5299 12.0099 19.9886 12.4686 20.554 12.4686C21.1193 12.4686 21.578 12.0099 21.578 11.4446C21.578 10.8792 21.1193 10.4206 20.554 10.4206V10.4209ZM11.6173 15.9997C11.6173 13.5797 13.5793 11.6175 15.9994 11.6174C18.4195 11.6174 20.3811 13.5796 20.3811 15.9997C20.3811 18.4198 18.4196 20.3811 15.9995 20.3811C13.5794 20.3811 11.6173 18.4198 11.6173 15.9997Z"
        fill={props.color || 'white'}
      />
      <path
        d="M16.0007 13.1562C17.5716 13.1562 18.8452 14.4297 18.8452 16.0007C18.8452 17.5716 17.5716 18.8452 16.0007 18.8452C14.4297 18.8452 13.1562 17.5716 13.1562 16.0007C13.1562 14.4297 14.4297 13.1562 16.0007 13.1562Z"
        fill={props.color || 'white'}
      />
    </svg>
  </span>
)

export default InstagramIcon
