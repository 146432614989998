import Link from 'next/link'
import DownloadAppstoreIcon from 'shared/icons/DownloadAppstoreIcon'
import DownloadAndroidIcon from 'shared/icons/DownloadAndroidIcon'
import InstagramIcon from 'shared/icons/InstagramIcon'
import TwitterIcon from 'shared/icons/TwitterIcon'
import LinkedInIcon from 'shared/icons/LinkedInIcon'
import NewsletterForm from 'shared/components/input/newsletter/NewsletterForm'
import { useDeeplinkToPage } from 'hooks/useShowingAppSuggestion'
import { cohartExternalLinks } from 'configs/index'
import { useTrackingDownloadIOSClicked } from 'hooks/eventTracking'
import TiktokIcon from 'shared/icons/TiktokIcon'
import cn from 'classnames'

const FooterSection = ({ curveColor = 'white' }: { curveColor?: string }) => {
  const deeplink = useDeeplinkToPage()
  const androidDeeplink = useDeeplinkToPage({ forIos: false })
  const trackClickingDownloadIos = useTrackingDownloadIOSClicked('footer_download_button')

  return (
    <div className="w-full bg-black font-monument-grotes">
      <div className="h-[98px] w-full -translate-y-[1px]">
        <div
          className="h-[98px] w-full rounded-b-[100%] border-none transition-all duration-300"
          style={{ backgroundColor: curveColor }}
        />
      </div>
      <div className="flex w-full flex-col bg-black p-7 pt-4 lg:px-10 lg:pb-[40px] lg:pt-16">
        <div className="flex w-full flex-col gap-8 border-b border-white/10 pt-8 lg:flex-row lg:gap-0 lg:pt-0">
          <div
            className={cn(
              'flex w-full flex-col gap-6 border-b border-white/10 pb-3 text-white',
              'lg:w-[60%] lg:flex-row lg:justify-between lg:pb-0 lg:pr-20',
            )}
          >
            <div className="flex flex-col gap-4 lg:gap-6">
              <p className="text-[20px] font-medium">Company</p>

              <Link passHref href="/" className="text-sm">
                About
              </Link>
              <Link passHref href="/contact-us" className="text-sm">
                Contact us
              </Link>
              <Link passHref href="/news" className="text-sm">
                News
              </Link>
            </div>

            <div className="flex flex-col gap-4 lg:gap-6">
              <p className="text-[20px] font-medium">Join the Community</p>

              <Link passHref href="/for-artists" className="text-sm">
                For Artists
              </Link>
              <Link passHref href="/for-collectors" className="text-sm">
                For Collectors
              </Link>
              <Link passHref href="/for-advisors" className="text-sm">
                For Advisors
              </Link>
            </div>

            <div className="flex flex-col gap-4 lg:gap-6">
              <p className="text-[20px] font-medium">Policy & Guidelines</p>

              <Link passHref href="/faqs" className="text-sm">
                FAQs
              </Link>
              <a className="text-sm" href={cohartExternalLinks.communityGuidelines} target="_blank" rel="noreferrer">
                Community Guidelines
              </a>
              <a className="text-sm" href={cohartExternalLinks.shippingGuidelines} target="_blank" rel="noreferrer">
                Shipping Guidelines
              </a>
              <Link passHref href="/terms-of-service" className="text-sm">
                Terms of Service
              </Link>
              <Link passHref href="/privacy-policy" className="text-sm">
                Privacy Policy
              </Link>
            </div>
          </div>

          <div className="flex w-full flex-col gap-8 pb-8 lg:w-[40%]">
            <div className="w-full">
              <p className="mb-1 text-sm uppercase text-white/60">Stay in touch</p>
              <div className="flex gap-5">
                <a href={cohartExternalLinks.instagram} target="_blank" rel="noreferrer">
                  <InstagramIcon />
                </a>
                <a href={cohartExternalLinks.twitter} target="_blank" rel="noreferrer">
                  <TwitterIcon />
                </a>
                <a href={cohartExternalLinks.tiktok} target="_blank" rel="noreferrer">
                  <TiktokIcon />
                </a>
                <a href={cohartExternalLinks.linkedIn} target="_blank" rel="noreferrer">
                  <LinkedInIcon />
                </a>
              </div>
            </div>

            <div className="w-full">
              <p className="mb-1 text-sm uppercase text-white/60">Find us on</p>
              <div className="inline-flex w-full items-center justify-start lg:gap-5">
                <a
                  href={deeplink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={trackClickingDownloadIos}
                  className="inline"
                >
                  <DownloadAppstoreIcon className="!h-[50px] !w-[148px] sm:!h-[61px] sm:!w-[180px]" />
                </a>
                <a href={androidDeeplink} target="_blank" rel="noreferrer">
                  <DownloadAndroidIcon className="!h-[50px] sm:!h-[61px]" />
                </a>
              </div>
            </div>

            <NewsletterForm />
          </div>
        </div>

        <div className="flex w-full flex-col justify-end pt-8 lg:pt-8">
          <p className="text-sm text-white/60">© 2024 Cohart. All rights reserved</p>
        </div>
      </div>
    </div>
  )
}

export default FooterSection
