const LinkedInIcon = () => {
  return (
    <span data-nosnippet>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_658_38687)">
          <path
            d="M16.0069 32C7.22529 32.0105 0.0177731 24.8135 3.25457e-05 16.0158C-0.0177081 7.21803 7.21965 -0.0169045 16.0141 2.9667e-05C24.8086 0.0169639 32.0041 7.21481 32 16.0037C31.996 24.7925 24.8022 31.9904 16.0069 32ZM24.4619 15.8924C24.4619 13.5055 24.4619 11.1183 24.4619 8.73081C24.4619 7.7704 23.9942 7.3027 23.0346 7.3027C18.314 7.3027 13.5934 7.3027 8.87275 7.3027C7.90508 7.3027 7.42689 7.76718 7.42609 8.71873C7.42286 13.4926 7.42286 18.2667 7.42609 23.041C7.42609 23.9732 7.90992 24.4538 8.83888 24.4546C13.5735 24.4546 18.3075 24.4546 23.041 24.4546C23.9861 24.4546 24.4611 23.9708 24.4611 23.0136C24.4622 20.6396 24.4624 18.2659 24.4619 15.8924Z"
            fill="white"
          />
          <path
            d="M14.0195 21.6515V13.9344H16.5839V14.7924C16.6814 14.869 16.7113 14.8174 16.7451 14.7763C17.5427 13.7279 18.624 13.5465 19.8094 13.8481C20.9795 14.1449 21.5496 15.0206 21.7883 16.1487C21.8526 16.4639 21.8831 16.7851 21.8794 17.1067C21.8746 18.6082 21.8794 20.1089 21.8794 21.6443H19.307V20.8524C19.307 19.7928 19.307 18.7332 19.307 17.6736C19.3079 17.4056 19.2836 17.138 19.2345 16.8745C19.0998 16.1527 18.6393 15.7455 17.9797 15.7633C17.283 15.7867 16.6685 16.3278 16.6363 17.014C16.5968 17.8841 16.6088 18.7566 16.6032 19.6283C16.6032 20.296 16.6032 20.9637 16.6032 21.6515H14.0195Z"
            fill="white"
          />
          <path d="M12.5837 13.9395V21.6486H10.0234V13.9395H12.5837Z" fill="white" />
          <path
            d="M11.2851 12.857C10.4457 12.857 9.85377 12.2925 9.85942 11.5014C9.86506 10.7103 10.4618 10.1693 11.3109 10.166C12.16 10.1628 12.7866 10.7563 12.7697 11.5458C12.7527 12.311 12.131 12.8602 11.2851 12.857Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_658_38687">
            <rect width="32" height="32.0008" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default LinkedInIcon
