const TiktokIcon = () => {
  return (
    <span data-nosnippet>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10832_7108)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM16.8539 6H20.2245C20.2245 8.55072 22.2123 10.6377 24.7474 10.7246V14.1449C23.1053 14.1159 21.5497 13.5652 20.2821 12.6377V19.6232C20.2821 23.1304 17.4589 26 13.9731 26C10.4873 26 7.66406 23.1594 7.66406 19.6232C7.66406 16.1159 10.4297 13.3043 13.8579 13.2464V16.6667C12.3022 16.7246 11.0346 18.029 11.0346 19.6232C11.0346 21.2464 12.331 22.5797 13.9443 22.5797C15.5576 22.5797 16.8539 21.2464 16.8539 19.6232V6Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_10832_7108">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default TiktokIcon
