import { useCallback, useState } from 'react'
import webApiClient from 'utils/webApiClient'
import { PATTERN } from 'configs/validation'
import { useForm } from 'react-hook-form'
import Button from 'shared/components/button/Button'

const NewsletterForm = () => {
  const [subscribeSuccess, setSubscribeSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; firstName?: string }>()

  const handleFormSubmit = async ({ email, firstName }: { email: string; firstName?: string }) => {
    setIsLoading(true)

    // invalid email
    if (!PATTERN.email.test(email)) {
      setStatus('Please provide a valid email address!')
      setIsLoading(false)
      return
    }

    const { data } = await webApiClient.post('/mailchimp', { email, firstName })
    const { ok, message } = data as { ok: boolean; message?: string }

    if (!ok) {
      setStatus(message as string)
      setIsLoading(false)

      return
    }

    setStatus("You're on the list! We'll send you future updates and look forward to keeping in touch!")
    setSubscribeSuccess(true)
    setIsLoading(false)
  }

  const handleOnFocus = useCallback(() => setStatus(''), [])

  return (
    <div className="relative w-full font-monument-grotes">
      <h2 className="mb-2 text-sm uppercase text-white/60">Subscribe to our newsletter</h2>
      {subscribeSuccess && <p className="text-white">{status}</p>}
      {!subscribeSuccess && (
        <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-5">
          <div className="flex flex-col gap-1">
            <div className="relative flex w-full max-w-[350px] items-center border-b border-white/60 py-2">
              <input
                className="w-full border-none bg-transparent p-0 text-white transition-all placeholder:text-white/60 focus:outline-0 focus:ring-0"
                placeholder="First name"
                onFocus={handleOnFocus}
                {...register('firstName')}
              />
            </div>
            <div className="relative flex w-full max-w-[350px] items-center border-b border-white/60 py-2">
              <input
                className="w-full border-none bg-transparent p-0 text-white transition-all placeholder:text-white/60 focus:outline-0 focus:ring-0"
                placeholder="Email address *"
                onFocus={handleOnFocus}
                {...register('email', {
                  required: { value: true, message: 'Required' },
                })}
              />
              <p className="text-[#FF0000]">{errors.email?.message}</p>
            </div>
            <p className="text-[#FF0000]">{status}</p>
          </div>

          <div className="inline-block">
            <Button
              name="Subscribe"
              wrapperClass="rounded-full bg-white !px-5 !py-2"
              contentClass="normal-case !text-base"
              loading={isLoading}
              type="submit"
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default NewsletterForm
